import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function About() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/about" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>About our workshop | Service Center</title>
        <meta
          name="description"
          content="We service & repair all types of small petrol engines: lawnmowers, chainsaws, water blasters, compressors, leaf blowers, generators, and trimmers"
        />
      </Helmet>

      <Layout title="About">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <p>
                  We service and repair almost anything with a small petrol
                  engine, including most makes and models of:
                </p>
                <ul>
                  <li>lawnmowers</li>
                  <li>chainsaws</li>
                  <li>water blasters</li>
                  <li>compressors</li>
                  <li>leaf blowers</li>
                  <li>generators</li>
                  <li>trimmers</li>
                </ul>
                <p>
                  We stock spare parts for most makes and models, if we don’t
                  have it in stock we will do our best to get it in for you.
                </p>
                <p>
                  We are service agents for: Karcher, Solo, Husqvarna, Briggs
                  &amp; Stratton, MTD, LawnMaster, Oleo Mac, Echo,
                  Masport/Morrison, Euroquip, Ryobi, Homelite, Kohler, and
                  Makita.
                </p>
              </div>
              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
